import React from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { makeStyles } from "@material-ui/core/styles";
import "./UsageToggle.css";

const useStyles = makeStyles({
  wrapper: {
    marginTop: 10,
    marginBottom: 8,
  },
  labelText: {
    fontSize: "0.68em",
    fontWeight: 700,
    letterSpacing: -0.5,
  },
  buttonGroupHolder: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
  },
  buttonGroup: {
    paddingTop: 5,
    width: "100%",
  },
  buttonSelected: {
    color: "white",
    backgroundColor: "#8E8E8E",
    border: "none",
  },
  noticeTextPositioning: {
    // background: 'yellow',
    paddingTop: 3,
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
  },
  noticeTextStyling: {
    width: "100%",
    fontWeight: 500,
    fontSize: "0.6em",
    letterSpacing: -0.5,
    marginTop: 5,
  },
});

export default function UsageToggle({ usage, setUsage }) {
  const classes = useStyles();

  const handleClick = (selection) => setUsage(selection);

  return (
    <div className={classes.wrapper}>
      <div className={classes.labelText}>How often do you use your filter?</div>
      <div className={classes.buttonGroupHolder}>
        <div className={classes.buttonGroup}>
          <ButtonGroup fullWidth size="large">
            <Button
              className={usage === "light" ? classes.buttonSelected : ""}
              onClick={() => handleClick("light")}
            >
              Light
            </Button>
            <Button
              className={usage === "average" ? classes.buttonSelected : ""}
              onClick={() => handleClick("average")}
            >
              Average
            </Button>
            <Button
              className={usage === "heavy" ? classes.buttonSelected : ""}
              onClick={() => handleClick("heavy")}
            >
              Heavy
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <div className={classes.noticeTextPositioning}>
        <div className={classes.noticeTextStyling}>
          *Average use represents only two people using an at home filter; or
          using a water bottle filter no more than four times per day
        </div>
      </div>
    </div>
  );
}
