import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import "./Confirm.css";
import fire from "../../fire";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles({
  container: {
    // height: '100%',
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  upperText: {
    fontWeight: 500,
    fontSize: "1.1em",
    paddingTop: 50,
    letterSpacing: -0.5,
  },
  addressText: {
    padding: 15,
    fontSize: "1.1em",
    // fontWeight: 500
  },
  digitInputContainer: {
    maxWidth: 200,
    width: "55%",
    display: "flex",
    justifyContent: "space-between",
    padding: 10,
  },
  digitInput: {
    height: 50,
    width: 50,
    textAlign: "center",
    fontSize: "1.5em",
    boxShadow: "0px 0px 5px 2px #adadad",
  },
  confirmButtonContainer: {
    padding: 20,
  },
  confirmButton: {
    color: "white",
    width: "13em",
    fontSize: "1.1em",
    backgroundColor: "#13C0DD",
  },
});

export default withRouter(function ConfirmEmail({ userUID, history, email }) {
  const classes = useStyles();

  const input1ref = React.createRef();
  const input2ref = React.createRef();
  const input3ref = React.createRef();

  const onSubmit = () => {
    const digit1 = input1ref.current.value;
    const digit2 = input2ref.current.value;
    const digit3 = input3ref.current.value;
    if (!digit1 || !digit2 || !digit3) {
      alert("Enter every digit");
      return;
    }
    const inputCode = Number(digit1 + digit2 + digit3);
    const userRef = fire.firestore().collection("users").doc(userUID);
    userRef.get().then((doc) => {
      if (doc.exists) {
        const { email_code } = doc.data();
        if (inputCode === email_code) {
          userRef.update({ email_verified: true });
          history.push("/");
        } else {
          alert("Wrong Code Sorry");
        }
      }
    });
  };

  const resendEmail = () => {
    const resendConfirmationEmail = fire
      .functions()
      .httpsCallable("resendConfirmationEmail");
    resendConfirmationEmail().then((res) => {
      console.log(res);
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.upperText}>We sent a confirmation code to:</div>
      <div className={classes.addressText}>{email}</div>
      <div className={classes.digitInputContainer}>
        <input
          type="number"
          ref={input1ref}
          className={classes.digitInput}
          onBeforeInput={() => {
            if (input1ref.current.value) input1ref.current.value = "";
          }}
          onInput={() => {
            if (input1ref.current.value !== "") input2ref.current.focus();
          }}
        />
        <input
          type="number"
          ref={input2ref}
          className={classes.digitInput}
          onBeforeInput={() => {
            if (input2ref.current.value) input2ref.current.value = "";
          }}
          onInput={() => {
            if (input2ref.current.value !== "") input3ref.current.focus();
          }}
        />
        <input
          type="number"
          ref={input3ref}
          className={classes.digitInput}
          onBeforeInput={() => {
            if (input3ref.current.value) input3ref.current.value = "";
          }}
          onInput={() => {
            if (input3ref.current.value !== "") onSubmit();
          }}
        />
      </div>
      <div className={classes.confirmButtonContainer}>
        <Button
          id="emailCodeConfirmButton"
          variant="contained"
          className={classes.confirmButton}
          onClick={onSubmit}
        >
          Confirm
        </Button>
      </div>
      <div>
        <Button onClick={resendEmail}>Resend Code</Button>
      </div>
      <div style={{ paddingTop: 5 }}>
        <Button
          onClick={() => {
            history.push("/register");
            fire.auth().signOut();
          }}
        >
          Sign up with different address
        </Button>
      </div>
    </div>
  );
});
