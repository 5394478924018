import React from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CardMedia from "@material-ui/core/CardMedia";
import { withRouter } from "react-router-dom";
import "./Header.css";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#F6F8F9",
    boxShadow: "none",
  },
  settings: {
    position: "absolute",
    right: 10,
    bottom: 0,
    cursor: "pointer",
  },
  wrapper: {
    flexGrow: 1,
    maxWidth: 500,
  },
  topRow: {
    display: "flex",
    justifyContent: "center",
    margin: "30px 25px",
    position: "relative",
  },
  brandText: {
    fontSize: "1.8em",
    fontWeight: 500,
    paddingLeft: "10px",
    userSelect: "none",
    opacity: "0.8",
  },
});

const locVals = {
  "/": 0,
  "/shop": 1,
  "/reminders": 2,
};

export default withRouter(function Header({ history, location }) {
  const classes = useStyles();
  const [screen, setScreen] = React.useState(locVals[location.pathname]);

  history.listen((location, action) => {
    if (action === "POP") {
      setScreen(locVals[location.pathname]);
    } else if (action === "PUSH") {
      setScreen(locVals[location.pathname]);
    }
  });

  function handleChange(event, newValue) {
    setScreen(newValue);
  }

  const HeaderTabs = withStyles({
    root: {
      border: "none",
      height: 63,
    },
    indicator: {
      backgroundColor: "#13C0DD",
    },
  })(Tabs);

  const HeaderTab = withStyles(() => ({
    root: {
      textTransform: "none",
      opacity: 1,
      "&:hover": {
        color: "#13C0DD",
      },
      "&$selected": {
        color: "#13C0DD",
      },
    },
    selected: {},
  }))((props) => <Tab disableRipple {...props} />);

  const tabValue = screen === undefined ? false : screen;

  return (
    <Paper square className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.topRow}>
          <CardMedia
            component="img"
            height="22"
            image={process.env.PUBLIC_URL + "/images/logos/logo-dark.svg"}
          />
          <CardMedia
            component="img"
            className={classes.settings}
            height="26"
            image={
              location.pathname === "/account"
                ? process.env.PUBLIC_URL +
                  "/images/material-icons/setting-blue.png"
                : process.env.PUBLIC_URL +
                  "/images/material-icons/setting-dark.png"
            }
            onClick={() => {
              if (location.pathname !== "/account") history.push("/account");
            }}
          />
        </div>
        <HeaderTabs
          value={tabValue}
          onChange={handleChange}
          variant="fullWidth"
        >
          <HeaderTab
            icon={
              <CardMedia
                component="img"
                height="26"
                image={
                  location.pathname === "/"
                    ? process.env.PUBLIC_URL +
                      "/images/material-icons/home-blue.png"
                    : process.env.PUBLIC_URL +
                      "/images/material-icons/home-dark.png"
                }
              />
            }
            label="Home"
            onClick={() => {
              if (location.pathname !== "/") history.push("/");
            }}
          />
          <HeaderTab
            icon={
              <CardMedia
                component="img"
                height="26"
                image={
                  location.pathname === "/shop"
                    ? process.env.PUBLIC_URL +
                      "/images/material-icons/cart-blue.png"
                    : process.env.PUBLIC_URL +
                      "/images/material-icons/cart-dark.png"
                }
              />
            }
            label="Shop"
            onClick={() => {
              if (location.pathname !== "/shop") history.push("/shop");
            }}
          />
          <HeaderTab
            icon={
              <CardMedia
                component="img"
                height="26"
                image={
                  location.pathname === "/reminders"
                    ? process.env.PUBLIC_URL +
                      "/images/material-icons/notification-blue.png"
                    : process.env.PUBLIC_URL +
                      "/images/material-icons/notification-dark.png"
                }
              />
            }
            label="Reminders"
            onClick={() => {
              if (location.pathname !== "/reminders")
                history.push("/reminders");
            }}
          />
        </HeaderTabs>
      </div>
    </Paper>
  );
});
