import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import PhoneIcon from "@material-ui/icons/PhoneOutlined";
import MaskedInput from "react-text-mask";

const useStyles = makeStyles({
  container: {
    flexGrow: 1,
    padding: "1em 0",
  },
  inputHolder: {
    position: "relative",
  },
  textInput: {
    width: "100%",
    borderRadius: 3,
    border: "solid 1px #13C0DD",
    fontSize: "1.1em",
    letterSpacing: -0.3,
    padding: 8,
    paddingLeft: 40,
    boxSizing: "border-box",
  },
  icon: {
    position: "absolute",
    top: 8,
    left: 8,
    color: "#13C0DD",
  },
  buttonContainer: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "space-between",
    marginTop: 15,
  },
  addButton: {
    color: "white",
    backgroundColor: "#13C0DD",
    width: "49%",
    height: 42,
  },
  cancelButton: {
    background: "#2A2A2A",
    color: "white",
    width: "49%",
    height: 42,
  },
  loginContainer: {
    padding: 16,
    textAlign: "center",
  },
  loginButton: {
    fontWeight: 700,
    fontSize: "1em",
  },
  radioGroup: {
    width: "100%",
    marginTop: 12,
    display: "flex",
    justifyContent: "center",
  },
});

export default function AddPhone({
  setAddPhoneDisplay,
  handlePhoneInput,
  setInputPhone,
  phone_number,
  addButtonDisabled,
  setAddButtonDisabled,
}) {
  const classes = useStyles();

  const placeholderPhone = `(${phone_number.substr(
    0,
    3
  )}) ${phone_number.substr(3, 3)} - ${phone_number.substr(6, 4)}`;

  return (
    <div className={classes.container}>
      <div className={classes.inputHolder}>
        <MaskedInput
          type="tel"
          mask={[
            "(",
            /[1-9]/,
            /\d/,
            /\d/,
            ")",
            " ",
            /\d/,
            /\d/,
            /\d/,
            "-",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ]}
          className={classes.textInput}
          placeholder={phone_number ? placeholderPhone : "Enter Your Number"}
          onChange={(event) => setInputPhone(event.target.value)}
        />
        <PhoneIcon className={classes.icon} />
      </div>
      <div className={classes.buttonContainer}>
        <Button
          id="cancelButton"
          variant="contained"
          className={classes.cancelButton}
          onClick={() => setAddPhoneDisplay("button")}
        >
          Cancel
        </Button>
        <Button
          id="addPhoneButton"
          variant="contained"
          disabled={addButtonDisabled}
          className={classes.addButton}
          onClick={() => {
            setAddButtonDisabled(true);
            handlePhoneInput();
          }}
        >
          {!!phone_number ? "Change Phone" : "Add Phone"}
        </Button>
      </div>
    </div>
  );
}
