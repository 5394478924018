import React from "react";
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  splashContainer: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
  },
});

export default function Splash() {
  const classes = useStyles();
  return (
    <div className={classes.splashContainer}>
      <CardMedia component="img" height="60" image={process.env.PUBLIC_URL + '/images/logos/santevia-logo.png'} />
    </div>
  );
}
