import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

/* Start Filter Ease Firebase Config PROD */
var config = {
  apiKey: "AIzaSyCYUcnmz8m67O2CfzeC-xt4ZhrdenvXPuk",
  authDomain: "filterease.firebaseapp.com",
  databaseURL: "https://filterease.firebaseio.com",
  projectId: "filterease",
  storageBucket: "filterease.appspot.com",
  messagingSenderId: "448846403021",
  appId: "1:448846403021:web:694ab2851b5ccacf"
};
/* END Filter Ease Firebase Config PROD */

/* START Filter Ease Firebase Config DEV */
// var config = {
//   apiKey: "AIzaSyD_ezHYSoiWTKlWU16ld4a3j10LY2YfeBs",
//   authDomain: "filterease-dev.firebaseapp.com",
//   projectId: "filterease-dev",
//   storageBucket: "filterease-dev.appspot.com",
//   messagingSenderId: "42277543324",
//   appId: "1:42277543324:web:10c8e54098d1a35ed73c59",
//   measurementId: "G-B87QBZD0DD"
// };
/* END Filter Ease Firebase Config DEV */


const fire = firebase.initializeApp(config);
export default fire;