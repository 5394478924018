import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import ProductSelect from "./ProductSelect";
import Countertop from "./Countertop";
import DateSelect from "./DateSelect";
import { UserContext } from "../../../Store";
import fire from "../../../fire";
import { products } from "../products";
import moment from "moment";
import { CardMedia } from "@material-ui/core";
import { useLocation, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  footerWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  buttonHolder: {
    display: "flex",
    justifyContent: "center",
    position: "fixed",
    bottom: 0,
    height: 95,
    width: "100%",
    background: "#2A2A2A",
    borderBottom: 0,
  },
  iconContainer: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: 500,
    width: "100%",
  },
  adminContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 15,
    marginLeft: 15,
    fontSize: 24,
    color: "#fff",
    height: "fit-content",
    cursor: "pointer",
  },
  adminIcon: {
    marginRight: 10,
  },
  addButtonContainer: {
    position: "relative",
  },
  addProduct: {
    display: "flex",
    alignItems: "center",
    padding: "10px 16px 10px 15px",
    position: "absolute",
    right: -2,
    top: -125,
    border: "1px solid #13C0DD",
    borderRadius: 3,
    [theme.breakpoints.up("sm")]: {
      flexDirection: "column",
      top: -160,
      right: -20,
      height: 55,
    },
  },
  text: {
    textAlign: "right",
    fontWeight: "bold",
    marginRight: 15,
    width: 115,
    [theme.breakpoints.up("sm")]: {
      marginRight: 0,
      textAlign: "center",
      marginBottom: 10,
    },
  },
  button: {
    top: -38,
    right: 0,
    height: 76,
    width: 76,
    marginRight: 16,
    backgroundColor: "#13C0DD",
    borderRadius: "50%",
    border: "8px solid #fff",
    "&:hover": {
      backgroundColor: "#13C0DD",
      border: "7px solid #fff",
    },
  },
  icon: {
    fontSize: 26,
    color: "#2A2A2A",
  },
  arrow: {
    fontSize: 35,
    color: "#13C0DD",
    marginRight: 22,
    [theme.breakpoints.up("sm")]: {
      marginRight: 0,
      color: "black",
      backgroundColor: "#13C0DD",
      borderRadius: "50%",
      fontSize: 25,
      padding: 7,
      bottom: -30,
    },
  },
}));

// Product reference mapping:
// 0 - Mina Pitcher
// 1 - Countertop
// 2 - Powerstick
// 3 - Bath Filter
// 4 - Shower Filter
// 5 - Classic Pitcher

export default function AddDialog({ isAdmin, noProducts }) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [product, setProduct] = useState(null);
  const [countertopFilter, setCountertopFilter] = useState(null);

  const [user] = React.useContext(UserContext);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
    setProduct(null);
    setCountertopFilter(null);
  }

  function AddProductToDatabase(productSelected, date) {
    const userRef = fire.firestore().collection("users").doc(user.uid);
    const { sku, lifeSpan, name, shortName } = productSelected;
    const dayDiff = Math.floor(lifeSpan * 0.1);

    userRef.collection("product").add({
      product: sku,
      last_replace_date: moment(date).format("M/D/YYYY"),
      usage: "average",
      light_life_span: lifeSpan + dayDiff,
      average_life_span: lifeSpan,
      heavy_life_span: lifeSpan - dayDiff,
      next_replace_date: moment(date).add(lifeSpan, "days").format("M/D/YYYY"),
      product_name: name,
      user_custom_name: shortName,
    });
  }

  function AddProduct(date) {
    if (product === 1) {
      const {
        fluorideFilter,
        fiveStageFilter,
        ceramicPreFilter,
        mineralStones,
      } = products.countertop;
      const mainFilter =
        countertopFilter === 0 ? fluorideFilter : fiveStageFilter;
      AddProductToDatabase(mainFilter, date);
      AddProductToDatabase(ceramicPreFilter, date);
      AddProductToDatabase(mineralStones, date);
    } else if (product === 2) {
      const {
        minaFilter,
        mineralStones
      } = products.glassWaterSystem;
      AddProductToDatabase(minaFilter, date);
      AddProductToDatabase(mineralStones, date);
    } else {
      let productSelected;
      switch (product) {
        case 5:
          productSelected = products.classicPitcher;
          break;
        case 0:
          productSelected = products.minaPitcher;
          break;
        case 3:
          productSelected = products.powerStick;
          break;
        case 4:
          productSelected = products.bathFilter;
          break;
        default:
          throw new Error();
      }
      AddProductToDatabase(productSelected, date);
    }
    handleClose();
  }

  return (
    <div>
      <div className={classes.footerWrapper}>
        <div className={classes.buttonHolder}>
          <div className={classes.iconContainer}>
            <div>
              {isAdmin && (
                <div
                  className={classes.adminContainer}
                  onClick={() => history.push("/admin")}
                >
                  <CardMedia
                    component="img"
                    height="35"
                    className={classes.adminIcon}
                    image={
                      location.pathname === "/admin"
                        ? process.env.PUBLIC_URL +
                          "/images/material-icons/admin-blue.png"
                        : process.env.PUBLIC_URL +
                          "/images/material-icons/admin-white.png"
                    }
                  />
                  Admin
                </div>
              )}
            </div>
            {location.pathname !== "/admin" && (
              <div className={classes.addButtonContainer}>
                {location.pathname === "/" && noProducts && (
                  <div className={classes.addProduct}>
                    <div className={classes.text}>Add Your First Product!</div>
                    <ArrowDownwardIcon className={classes.arrow} />
                  </div>
                )}
                <Button
                  className={classes.button}
                  varient="contained"
                  color="default"
                  onClick={handleClickOpen}
                >
                  <AddIcon className={classes.icon} />
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Dialog open={open} onClose={handleClose} maxWidth="xs">
        <DialogContent>
          {product === null && <ProductSelect setProduct={setProduct} />}
          {product === 1 && countertopFilter === null && (
            <Countertop setCountertopFilter={setCountertopFilter} />
          )}
          {/* Show date select if product is pitcher / power stick */}
          {/* Or countertop and filter has been selected */}
          {([0, 2, 3, 4, 5].includes(product) ||
            (product === 1 && countertopFilter !== null)) && (
            <DateSelect AddProduct={AddProduct} setProduct={setProduct} />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
