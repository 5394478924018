import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CloseIcon from "@material-ui/icons/Close";
import "./Confirm.css";
import fire from "../../fire";

const useStyles = makeStyles({
  card: {
    background: "#F4F4F4",
    marginTop: 12,
  },
  container: {
    // height: '100%',
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  close: {
    position: "absolute",
    fontSize: 15,
    top: 5,
    right: 5,
    color: "#5C5C5C",
    cursor: "pointer",
  },
  upperText: {
    fontWeight: 500,
    color: "#5C5C5C",
    paddingTop: "1em",
    letterSpacing: -0.5,
  },
  addressText: {
    padding: 10,
    fontSize: "1.2em",
    color: "#2A2A2A",
    fontWeight: "bold",
  },
  digitInputContainer: {
    maxWidth: 200,
    width: "70%",
    display: "flex",
    justifyContent: "space-between",
    padding: 10,
  },
  digitInput: {
    height: 50,
    width: 50,
    textAlign: "center",
    fontSize: "1.5em",
    borderRadius: 3,
    borderColor: "#5C5C5C",
  },
  confirmButtonContainer: {
    width: "100%",
    padding: "1em 0",
    display: "flex",
    justifyContent: "space-evenly",
  },
  confirmButton: {
    color: "white",
    backgroundColor: "#13C0DD",
    // width: '13em',
    // fontSize: '1.1em'
  },
  backButton: {
    background: "red",
    color: "white",
    width: 85,
  },
  resendButtonHolder: {
    marginTop: 20,
    marginBottom: 20,
    color: "#5C5C5C",
  },
  sendIt: {
    color: "#2A2A2A",
    textDecoration: "underline",
    fontWeight: "bold",
    cursor: "pointer",
  },
});

export default function ConfirmPhone({
  cancelPhoneEntryProcess,
  setAddPhoneDisplay,
  userUID,
  phone_number,
}) {
  const classes = useStyles();
  const input1ref = React.createRef();
  const input2ref = React.createRef();
  const input3ref = React.createRef();

  const displayPhone = `(${phone_number.substr(0, 3)}) ${phone_number.substr(
    3,
    3
  )} - ${phone_number.substr(6, 4)}`;

  const onSubmit = () => {
    const digit1 = input1ref.current.value;
    const digit2 = input2ref.current.value;
    const digit3 = input3ref.current.value;
    if (!digit1 || !digit2 || !digit3) {
      alert("Enter every digit");
      return;
    }
    const inputCode = Number(digit1 + digit2 + digit3);
    const userRef = fire.firestore().collection("users").doc(userUID);
    userRef.get().then((doc) => {
      if (doc.exists) {
        const { sms_code } = doc.data();
        if (inputCode === sms_code) {
          userRef.update({ sms_verified: true, sms_permission: true });
          setAddPhoneDisplay("button");
          alert("Success! You've added a new number to your account");
        } else {
          alert("Wrong Code Sorry");
        }
      }
    });
  };

  const resendText = () => {
    const resendConfirmationText = fire
      .functions()
      .httpsCallable("resendConfirmationText");
    resendConfirmationText().then((res) => {
      console.log(res);
    });
  };

  return (
    <Card className={classes.card}>
      <div className={classes.container}>
        <CloseIcon
          className={classes.close}
          onClick={cancelPhoneEntryProcess}
        />
        <div className={classes.upperText}>We sent a confirmation code to:</div>
        <div className={classes.addressText}>{displayPhone}</div>
        <div className={classes.digitInputContainer}>
          <input
            type="number"
            ref={input1ref}
            className={classes.digitInput}
            onBeforeInput={() => {
              if (input1ref.current.value) input1ref.current.value = "";
            }}
            onInput={() => {
              if (input1ref.current.value !== "") input2ref.current.focus();
            }}
          />
          <input
            type="number"
            ref={input2ref}
            className={classes.digitInput}
            onBeforeInput={() => {
              if (input2ref.current.value) input2ref.current.value = "";
            }}
            onInput={() => {
              if (input2ref.current.value !== "") input3ref.current.focus();
            }}
          />
          <input
            type="number"
            ref={input3ref}
            className={classes.digitInput}
            onBeforeInput={() => {
              if (input3ref.current.value) input3ref.current.value = "";
            }}
            onInput={() => {
              if (input3ref.current.value !== "") onSubmit();
            }}
          />
        </div>
        <div className={classes.resendButtonHolder}>
          Didn't get the text?{" "}
          <span className={classes.sendIt} onClick={resendText}>
            Send it again!
          </span>
        </div>
      </div>
    </Card>
  );
}
