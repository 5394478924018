import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  header: ({ welcome }) => ({
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: 75,
    maxWidth: "100%",
    backgroundColor: "#F6F8F9",
    marginTop: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      position: "relative",
      backgroundColor: welcome ? "white" : "#F6F8F9",
      marginTop: welcome && 20,
    },
  }),
  headerContent: {
    display: "flex",
    alignItems: "center",
    visibility: "hidden",
    width: 0,
    height: 75,
    [theme.breakpoints.up("md")]: {
      visibility: "visible",
      justifyContent: "space-between",
      width: 900,
    },
  },
  logoHolder: ({ welcome }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    visibility: "visible",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  }),
  headerImg: {
    [theme.breakpoints.up("md")]: {
      width: 150,
    },
  },
  links: {
    visibility: "hidden",
    width: 0,
    [theme.breakpoints.up("md")]: {
      visibility: "visible",
      display: "flex",
      width: 60,
      justifyContent: "space-between",
      fontSize: 14,
    },
  },
}));

export default ({ welcome }) => {
  const classes = useStyles({ welcome });
  const image = welcome
    ? "/images/logos/logo-dark-tagline.svg"
    : "/images/logos/logo-dark.svg";

  return (
    <div className={classes.header}>
      <div className={classes.headerContent}>
        <Link href="https://www.santevia.com">
          <img
            src={process.env.PUBLIC_URL + "/images/logos/logo-dark-tagline.svg"}
            alt="Santevia logo"
            className={classes.headerImg}
            width={234}
          />
        </Link>
        {/* <div className={classes.links}>
          <Link
            href="https://santevia.com/collections"
            target="_blank"
            color="inherit"
          >
            Shop
          </Link>
          <Link
            href="https://santevia.com/pages/why-santevia"
            target="_blank"
            color="inherit"
          >
            About
          </Link>
          <Link
            href="https://support.santevia.com/l/en"
            target="_blank"
            color="inherit"
          >
            Support
          </Link>
        </div> */}
      </div>
      <div className={classes.logoHolder}>
        <CardMedia
          component="img"
          height={welcome ? 60 : 20}
          image={process.env.PUBLIC_URL + image}
        />
      </div>
    </div>
  );
};
