import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Select, MenuItem, Button, InputBase } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import fire from "../fire";

const CustomInput = withStyles((theme) => ({
  root: {
    width: "100%",
  },
  input: {
    position: "relative",
    backgroundColor: "#F4F4F4",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
  },
}))(InputBase);

const useStyles = makeStyles({
  container: {
    paddingTop: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: 145,
  },
  card: {
    maxWidth: 400,
    width: "calc(95% - 32px)",
    padding: 16,
    margin: 16,
  },
  headerText: {
    fontSize: "1.2em",
    fontWeight: 700,
    letterSpacing: -0.5,
    color: "#13C0DD",
  },
  inputHolder: {
    marginTop: 12,
    position: "relative",
  },
  input: {
    width: "calc(100% - 16px)",
    borderRadius: 3,
    border: "solid 1px #707070",
    fontSize: "1.1em",
    letterSpacing: -0.3,
    padding: 8,
  },
  icon: {
    position: "absolute",
    top: 8,
    left: 8,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: 20,
  },
  button: {
    backgroundColor: "#13C0DD",
    border: "none",
    boxShadow: "none",
    height: 50,
    fontSize: 15,
    color: "white",
    width: "100%",
    "&:hover": {
      backgroundColor: "#13C0DD",
      color: "white",
    },
  },
  buttonBottom: {
    backgroundColor: "#C41230",
    border: "none",
    boxShadow: "none",
    height: 50,
    fontSize: 15,
    color: "white",
    width: "100%",
    marginTop: 10,
    "&:hover": {
      backgroundColor: "#C41230",
      color: "white",
    },
  },
  subContainer: {
    marginTop: 15,
  },
  subTitle: {
    marginBottom: 8,
  },
});

export default withRouter(function Admin({ userData, userUID, history }) {
  const classes = useStyles();
  const [settings, setSettings] = React.useState({
    first: userData.first || "",
    last: userData.last || "",
    purchase_location: userData.purchase_location || "",
    country: userData.country || "",
  });

  const handleChange = (event) => {
    const obj = { ...settings };
    obj[event.target.id || event.target.name] = event.target.value;
    setSettings(obj);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    fire
      .firestore()
      .collection("users")
      .doc(userUID)
      .update(settings)
      .then(() => {
        alert("Account Successfully Updated");
      })
      .catch(() => {
        alert("Error during Account Update");
      });
  };

  const handleLogout = (event) => {
    event.preventDefault();
    fire.auth().signOut();
    history.push("/");
  };

  return (
    <div className={classes.container}>
      <div className={classes.card}>
        <div className={classes.headerText}>Account Settings</div>
        <div>
          <div className={classes.inputHolder}>
            <input
              type="text"
              id="first"
              className={classes.input}
              placeholder="First Name"
              value={settings.first}
              onChange={handleChange}
            />
          </div>
          <div className={classes.inputHolder}>
            <input
              type="text"
              id="last"
              className={classes.input}
              placeholder="Last Name"
              value={settings.last}
              onChange={handleChange}
            />
          </div>
          <div className={classes.subContainer}>
            <div className={classes.subTitle}>Purchase Location</div>
            <Select
              name="purchase_location"
              value={settings.purchase_location}
              onChange={handleChange}
              input={<CustomInput />}
            >
              <MenuItem value="in-store">In-store</MenuItem>
              <MenuItem value="santevia.com">Santevia.com</MenuItem>
              <MenuItem value="amazon">Amazon</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            onClick={handleSubmit}
            className={classes.button}
          >
            Save
          </Button>
          <Button
            variant="contained"
            onClick={handleLogout}
            className={classes.buttonBottom}
          >
            Logout
          </Button>
        </div>
      </div>
    </div>
  );
});
