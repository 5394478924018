import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";
import { imageMap } from "../ProductCard";

const useStyles = makeStyles({
  mainGrid: {
    width: "90%",
    display: "grid",
    gridTemplateColumns: "minmax(130px, 40%) auto",
    marginTop: 15,
    borderBottom: "1px solid black",
    paddingBottom: 7,
    textAlign: "center",
  },
  imageHolder: {
    display: "flex",
    justifyContent: "center",
    // borderRight: 'solid 1px #bbb',
    paddingLeft: 12,
  },
  filterNameText: {
    fontSize: "1.5em",
    fontWeight: 700,
    letterSpacing: -0.5,
    borderBottom: "solid 1px lightblue",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingTop: 5,
  },
  productNameText: {
    display: "flex",
    alignItems: "center",
    fontSize: "0.9em",
    fontWeight: 700,
    letterSpacing: -1,
    opacity: 0.7,
  },
  remainingText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    fontWeight: 700,
    fontSize: "0.75em",
  },
});

export default function ProductInfo({
  productData,
  daysRemaining,
  percentRemaining,
}) {
  const classes = useStyles();
  return (
    <div className={classes.mainGrid}>
      <div className={classes.imageHolder}>
        <CardMedia
          component="img"
          height="120"
          image={imageMap[productData.product]}
        />
      </div>
      <div className={classes.remainingText}>
        <div
          style={{
            fontSize: "6em",
            fontWeight: 700,
          }}
        >
          {daysRemaining}
        </div>
        <div
          style={{
            paddingLeft: 5,
            fontWeight: "normal",
            color: "#13C0DD",
          }}
        >
          {daysRemaining === 1 ? "Day Remaining" : "Days Remaining"}
        </div>
      </div>
    </div>
  );
}
