import React from "react";
import "./App.css";
import Header from "./components/Header";
import Auth from "./components/Auth/Auth";
import Login from "./components/Auth/Login";
import Welcome from "./components/Auth/Welcome";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./components/Home/Home";
import Shop from "./components/Shop/Shop";
import Reminders from "./components/Reminders/Reminders";
import fire from "./fire";
import Register from "./components/Auth/Register";
import ConfirmEmail from "./components/Auth/ConfirmEmail";
import Admin from "./components/Admin";
import Account from "./components/Account";
import { UserContext } from "./Store";
import Splash from "./Splash";
import AddDialog from "./components/Home/AddProduct/AddDialog";
import Footer from "./components/Auth/Footer";

export default function App() {
  const [user, setUser] = React.useContext(UserContext);
  const [userData, setUserData] = React.useState(null);
  const [products, setProducts] = React.useState([]);
  const [noProducts, setNoProducts] = React.useState(false);

  React.useEffect(() => {
    if (user) {
      const unsubscribe = fire
        .firestore()
        .collection("users")
        .doc(user.uid)
        .collection("product")
        .onSnapshot((qs) => {
          const productsDontExist = !qs.docs.length;
          setNoProducts(productsDontExist);
          setProducts(qs.docs);
        });
      return () => {
        unsubscribe();
      };
    }
  }, [user]);

  fire.auth().onAuthStateChanged((user) => {
    if (user) {
      if (!localStorage.getItem("loggedIn")) {
        localStorage.setItem("loggedIn", true);
      }
      fire
        .firestore()
        .collection("users")
        .doc(user.uid)
        .onSnapshot((doc) => {
          if (doc.exists) {
            const data = doc.data();
            delete data.email_code;
            if (!userData) {
              setUserData(data);
            }
          }
        });
      setUser(user);
    } else {
      localStorage.removeItem("loggedIn");
      setUser(null);
      setUserData(null);
    }
  });

  if (user && userData) {
    if (userData.email_verified) {
      return (
        <Router>
          <div className="header">
            <Header />
          </div>
          <Switch>
            <Route exact path="/" render={() => <Home products={products} />} />
            <Route path="/shop" component={Shop} />
            <Route
              path="/reminders"
              render={(props) => (
                <Reminders {...props} userData={userData} userUID={user.uid} />
              )}
            />
            <Route
              path="/account"
              render={(props) => (
                <Account {...props} userData={userData} userUID={user.uid} />
              )}
            />
            <Route path="/admin" render={(props) => <Admin {...props} />} />
            <Route component={Home} />
          </Switch>
          <AddDialog isAdmin={userData.is_admin} noProducts={noProducts} />
        </Router>
      );
    } else {
      return (
        <Router>
          <Auth>
            <Switch>
              <Route
                path="/confirm-email"
                render={(props) => (
                  <ConfirmEmail
                    {...props}
                    userUID={user.uid}
                    email={userData.email}
                  />
                )}
              />
              <Route
                render={(props) => (
                  <ConfirmEmail
                    {...props}
                    userUID={user.uid}
                    email={userData.email}
                  />
                )}
              />
            </Switch>
          </Auth>
        </Router>
      );
    }
  } else {
    if (localStorage.getItem("loggedIn")) return <Splash />;
    return (
      <Router>
        <Auth>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/register" component={Register} />
            <Route component={Welcome} />
          </Switch>
          <Footer />
        </Auth>
      </Router>
    );
  }
}
