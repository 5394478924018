import React, { useState } from 'react';
import fire from './fire';

export const UserContext = React.createContext();

export default ({children}) => {
  const [user, setUser] = useState(fire.auth().currentUser);

  return (
    <UserContext.Provider value={[user, setUser]}>
      {children}
    </UserContext.Provider>
  )
}