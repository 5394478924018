import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CardMedia from "@material-ui/core/CardMedia";
import CheckIcon from "@material-ui/icons/CheckCircle";
import "./ProductSelect.css";

const useStyles = makeStyles({
  titleHolder: {
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
  },
  titleText: {
    fontSize: "1.3em",
    textAlign: "center",
    width: "80%",
    fontWeight: 700,
  },
  imageHolder: {
    display: "flex",
    justifyContent: "center",
    margin: "40px 0 30px",
  },
  buttonHolder: {
    textAlign: "center",
    marginTop: 30,
  },
  button: {
    borderRadius: 0,
    backgroundColor: "#13C0DD",
    border: "none",
    boxShadow: "none",
    height: 50,
    fontSize: 15,
    color: "white",
    width: "100%",
    "&:hover": {
      backgroundColor: "#13C0DD",
      color: "white",
    },
  },
  filterTextHolder: {
    textAlign: "center",
    marginTop: 40,
  },
  filterText: {
    fontSize: "0.8em",
    letterSpacing: -0.3,
    fontWeight: 700,
  },
  imagePosition: {
    position: "relative",
  },
  iconPosition: {
    position: "absolute",
    bottom: "32%",
    right: "50%",
    transform: "translate(50%, 50%)",
  },
  icon: {
    fontSize: "2em",
    color: "#13C0DD",
    backgroundColor: "white",
    borderRadius: 50,
  },
});

export default function Countertop({ setCountertopFilter }) {
  const classes = useStyles();

  const [filterSelected, setFilterSelected] = React.useState(0);

  return (
    <div>
      <div className={classes.titleHolder}>
        <div className={classes.titleText}>
          Select your filter in your Gravity Water System:
        </div>
      </div>
      <div>
        <div className={classes.imageHolder}>
          <div
            className={classes.imagePosition}
            onClick={() => setFilterSelected(0)}
          >
            <CardMedia
              component="img"
              height="130"
              image={"fluoride-filter.jpg"}
            />
            <div className={classes.filterTextHolder}>
              <div className={classes.filterText}>Fluoride Filter</div>
            </div>
            <div className={classes.iconPosition}>
              {filterSelected === 0 && <CheckIcon className={classes.icon} />}
            </div>
          </div>
          <div
            className={classes.imagePosition}
            onClick={() => setFilterSelected(1)}
          >
            <CardMedia
              component="img"
              height="130"
              image={"5-stage-filter.jpg"}
            />
            <div className={classes.filterTextHolder}>
              <div className={classes.filterText}>5-Stage Filter</div>
            </div>
            <div className={classes.iconPosition}>
              {filterSelected === 1 && <CheckIcon className={classes.icon} />}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.buttonHolder}>
        <Button
          id="bingo"
          variant="contained"
          className={classes.button}
          onClick={() => setCountertopFilter(filterSelected)}
        >
          Select
        </Button>
      </div>
    </div>
  );
}
