import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import CardMedia from "@material-ui/core/CardMedia";
import "./ProductSelect.css";

const images = [
  "mina-pitcher.jpg",
  "gravity-water-system.jpg",
  "glass-water-system.jpg",
  "water-bottle-filter.jpg",
  "bath-filter-new.jpg",
  "ClassicPitcher.jpg",
];

const useStyles = makeStyles({
  titleHolder: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 20,
  },
  titleText: {
    fontSize: "1.4em",
    textAlign: "center",
    width: "80%",
    fontWeight: 700,
  },
  imageHolder: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginTop: 10,
  },
  button: {
    borderRadius: 0,
    backgroundColor: "#13C0DD",
    border: "none",
    boxShadow: "none",
    height: 50,
    fontSize: 15,
    color: "white",
    width: "100%",
    "&:hover": {
      backgroundColor: "#13C0DD",
      color: "white",
    },
  },
  text: {
    marginTop: 10,
  },
});

export default function ProductSelect({ setProduct }) {
  const classes = useStyles();

  const [activeStep, setActiveStep] = React.useState(0);

  const productName = [
    "MINA Pitcher",
    "Gravity Water System",
    "Glass Water System",
    "Water Bottle Filter",
    "Bath Filter",
    "Classic Pitcher"
  ];

  function handleNext() {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  function handleBack() {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  return (
    <div>
      <div className={classes.titleHolder}>
        <div className={classes.titleText}>Select your product below:</div>
      </div>
      <div>
        <div className={classes.imageHolder}>
          <CardMedia component="img" height="200" image={images[activeStep]} />
          <div className={classes.text}>{productName[activeStep]}</div>
        </div>
        <MobileStepper
          variant="dots"
          steps={6}
          position="static"
          activeStep={activeStep}
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              <KeyboardArrowLeft />
            </Button>
          }
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === 5}
            >
              <KeyboardArrowRight />
            </Button>
          }
        />
      </div>
      <Button
        id="bingo"
        className={classes.button}
        onClick={() => setProduct(activeStep)}
      >
        Select
      </Button>
    </div>
  );
}
