import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  mainContainer: {
    flexGrow: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "#fff",
  },
});

export default ({ children }) => {
  const classes = useStyles();

  return <div className={classes.mainContainer}>{children}</div>;
};
