import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { DialogContent } from "@material-ui/core";
import fire from "../../../../fire";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginTop: 15,
  },
  updateButton: {
    color: "white",
    backgroundColor: "#13C0DD",
    width: "49%",
  },
  deleteButton: {
    backgroundColor: "#2A2A2A",
    color: "white",
    width: "49%",
  },
  confirmDeleteDialog: {
    // width: '50%',
    // display: 'flex',
    // justifyContent: 'center'
  },
  innerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 15,
    // padding: 30
  },
  confirmMessageText: {
    fontSize: "1.3em",
    fontWeight: 500,
  },
  buttonContainer: {
    display: "flex",
    // margin: 10
  },
  buttonHolder: {
    padding: 20,
  },
});

function ConfirmDialog({
  open,
  handleClose,
  outerHandleClose,
  user,
  productID,
  userUID,
  refresh,
}) {
  const classes = useStyles();

  function deleteProduct() {
    fire
      .firestore()
      .collection("users")
      .doc(userUID || user.uid)
      .collection("product")
      .doc(productID)
      .delete()
      .then(() => {
        refresh();
        outerHandleClose();
      });
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        className={classes.confirmDeleteDialog}
      >
        <DialogContent>
          <div className={classes.innerContainer}>
            <div className={classes.confirmMessageText}>Are You Sure?</div>
            <div className={classes.buttonContainer}>
              <div className={classes.buttonHolder}>
                <Button variant="contained" onClick={handleClose}>
                  Cancel
                </Button>
              </div>
              <div className={classes.buttonHolder}>
                <Button
                  variant="contained"
                  style={{ background: "red", color: "white" }}
                  onClick={deleteProduct}
                >
                  Delete
                </Button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default function SubmitButtons({
  updateProduct,
  user,
  productID,
  outerHandleClose,
  userUID,
  refresh,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <div>
      <div className={classes.container}>
        <Button
          variant="contained"
          className={classes.deleteButton}
          onClick={handleClickOpen}
        >
          Delete
        </Button>
        <Button
          variant="contained"
          className={classes.updateButton}
          onClick={updateProduct}
        >
          Update
        </Button>
      </div>
      <ConfirmDialog
        open={open}
        handleClose={handleClose}
        user={user}
        productID={productID}
        outerHandleClose={outerHandleClose}
        userUID={userUID}
        refresh={refresh}
      />
    </div>
  );
}
