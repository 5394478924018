import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ProductCard from "./ProductCard";

const useStyles = makeStyles({
  cardList: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: 145,
    paddingTop: 23,
  },
});

export default function Home({ products }) {
  const classes = useStyles();

  return (
    <div style={{ width: "100%" }}>
      <div className={classes.cardList}>
        {products.map((product, idx) => {
          return <ProductCard key={idx} product={product} />;
        })}
      </div>
    </div>
  );
}
