import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CartIcon from "@material-ui/icons/ShoppingCart";
import SearchIcon from "@material-ui/icons/Search";
import "./Shop.css";

const useStyles = makeStyles({
  container: {
    paddingTop: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: 145,
  },
  icon: {
    marginRight: 10,
  },
  card: {
    maxWidth: 400,
    width: "calc(95% - 32px)",
    margin: 16,
  },
  orContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "20px 0",
    width: 280,
  },
  line: {
    height: 2,
    width: 106,
    backgroundColor: "#2A2A2A",
    borderRadius: 5,
  },
  or: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#2A2A2A",
  },
  buttonContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 10
  },
  buttonShop: {
    backgroundColor: "#13C0DD",
    border: "none",
    boxShadow: "none",
    height: 50,
    fontSize: 16,
    width: "100%",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#13C0DD",
      color: "#fff",
    },
  },
  imgContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  buttonFindRetailer: {
    backgroundColor: "#2A2A2A",
    border: "none",
    boxShadow: "none",
    height: 50,
    fontSize: 16,
    width: "100%",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#2A2A2A",
      color: "#fff",
    },
  },
});

export default () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.card}>
        <div className={classes.imgContainer}>
          <img
            src={process.env.PUBLIC_URL + "product-group-new.jpg"}
            alt="Santevia products"
            width="60%"
            alignSelf= "center"
          />
        </div>
        <div className={classes.buttonContainer}>
        <a
            href="https://www.santevia.com/store-locator/?utm_source=FilterEase&utm_medium=StoreLocator"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", width: "100%" }}
          >
            <Button id="loginDirectButton" className={classes.buttonFindRetailer}>
              <SearchIcon className={classes.icon} />
              Find a Retailer Near You
            </Button>
          </a>
          <div className={classes.orContainer}>
            <div className={classes.line}></div>
            <div className={classes.or}>or</div>
            <div className={classes.line}></div>
          </div>
          <a
            href="https://www.santevia.com?utm_source=FilterEase&utm_medium=SanteviaShop"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", width: "100%" }}
          >
            <Button id="loginDirectButton" className={classes.buttonShop}>
              <CartIcon className={classes.icon} />
              Shop Online
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};
