import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles({
  grid: {
    display: "grid",
    gridTemplateRows: "30px auto",
    margin: "10px 0",
  },
  upperFlex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  mainText: {
    fontSize: "1.2em",
    fontWeight: 500,
    letterSpacing: -0.3,
  },
  subText: {
    fontSize: "0.9em",
    opacity: 0.6,
    fontWeight: 500,
  },
});

const CustomSwitch = withStyles({
  switchBase: {
    "&$checked": {
      color: "#2A2A2A",
    },
  },
  checked: {},
})(Switch);

const data = [
  ["sms_permission", "Receive text reminders"],
  ["email_permission", "Receive email reminders"],
];

export default function Switches({ userData, input, setInput }) {
  const classes = useStyles();

  const { email_permission, sms_permission, sms_verified } = userData;
  const [state, setState] = React.useState({
    sms_permission,
    email_permission,
  });

  const handleChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.checked });
    setInput({ ...input, [name]: event.target.checked });
  };

  return (
    <div>
      {data.map((item, idx) => {
        return (
          <div key={idx} className={classes.grid}>
            <div className={classes.upperFlex}>
              <div className={classes.mainText}>{item[1]}</div>
              <CustomSwitch
                disabled={item[0] === "sms_permission" && !sms_verified}
                checked={state[item[0]]}
                onChange={handleChange(item[0])}
                value={item[0]}
                color="primary"
              />
            </div>
            <div className={classes.subText}>
              {item[0] === "sms_permission" &&
                !sms_verified &&
                "Add a phone number above to receive text updates"}
            </div>
          </div>
        );
      })}
    </div>
  );
}
