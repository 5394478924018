import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  wrapper: {
    marginTop: 10,
    marginBottom: 8,
  },
  labelText: {
    fontSize: "0.68em",
    fontWeight: 700,
    letterSpacing: -0.5,
  },
  textInput: {
    width: "calc(100% - 10px)",
    marginTop: 3,
    borderRadius: 3,
    border: "solid 1px #8E8E8E",
    fontSize: "1.1em",
    letterSpacing: -0.3,
    padding: 8,
    paddingRight: 0,
    color: "#13C0DD",
  },
});

export default function NameInput({ name, setName, placeholderName }) {
  const classes = useStyles();

  React.useEffect(() => {
    setName(placeholderName);
  }, [placeholderName, setName]);

  const handleInput = (event) => {
    setName(event.target.value);
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.labelText}>Update Filter Name:</div>
      <input
        type="text"
        className={classes.textInput}
        value={name}
        onChange={handleInput}
      />
    </div>
  );
}
