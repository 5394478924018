import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ProductModal from "./Modal/ProductModal";

const useStyles = makeStyles({
  mainGrid: {
    height: "100%",
    display: "grid",
    gridTemplateColumns: "6fr 1fr",
    gridTemplateRows: "38% 15% auto",
    boxSizing: "border-box",
  },
  productName: {
    '@media (max-width: 414px)': {
      fontSize: "18px"
    },
    '@media (min-width: 415px) and (max-width: 470px)': {
      fontSize: "22px"
    },
    fontSize: "25px",
    fontWeight: 700,
    paddingLeft: 16,
    paddingTop: 12,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  productDescription: {
    gridArea: "2 / 1 / 3 / last-line",
    paddingTop: 4,
    paddingLeft: 16,
    '@media (max-width: 414px)': {
      fontSize: "0.7em"
    },
    '@media (min-width: 415px) and (max-width: 470px)': {
      fontSize: "0.76em"
    },
    fontSize: "0.8em",
    fontWeight: 500,
    letterSpacing: "-0.03em",
    color: "#777",
  },
  daysRemaining: {
    gridArea: "3 / 1",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    paddingLeft: 16,
  },
  day: {
    fontSize: "30pt",
    fontWeight: "bold",
    marginRight: 10,
  },
  remainingText: {
    paddingBottom: 10,
    fontSize: "0.8em",
    fontWeight: 500,
    letterSpacing: "-0.03em",
    color: "#13C0DD",
  },
  iconHolder: {
    gridArea: "1 / 2 / 3 / last-line",
    display: "flex",
    justifyContent: "flex-end",
  },
  icon: {
    fontSize: 25,
    color: "black",
  },
});

export default function CardInfo({
  productData,
  daysRemaining,
  percentRemaining,
  productID,
  color,
  userUID,
  refresh,
}) {
  const classes = useStyles();

  return (
    <div className={classes.mainGrid}>
      <div className={classes.productName}>{productData.user_custom_name}</div>
      <div className={classes.productDescription}>
        {productData.product_name}
      </div>
      <div className={classes.daysRemaining}>
        <div className={classes.day} style={{ color }}>
          {daysRemaining}
        </div>
        <div className={classes.remainingText}>
          Day{daysRemaining !== 1 ? "s" : ""} Remaining
        </div>
      </div>
      <div className={classes.iconHolder}>
        <ProductModal
          productData={productData}
          daysRemaining={daysRemaining}
          percentRemaining={percentRemaining}
          productID={productID}
          userUID={userUID}
          refresh={refresh}
        />
      </div>
    </div>
  );
}
