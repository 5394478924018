import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Select, MenuItem, InputBase } from "@material-ui/core";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIcon from "@material-ui/icons/Phone";
import AddPhone from "../Auth/AddPhone";
import ConfirmPhone from "../Auth/ConfirmPhone";
import fire from "../../fire";

const CustomInput = withStyles((theme) => ({
  root: {
    width: "100%",
  },
  input: {
    position: "relative",
    backgroundColor: "#F4F4F4",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
  },
}))(InputBase);


const useStyles = makeStyles({
  inputHolder: {
    marginTop: 12,
    position: "relative",
  },
  input: {
    width: "100%",
    borderRadius: 3,
    border: "solid 1px #8E8E8E",
    fontSize: "1.1em",
    letterSpacing: -0.3,
    padding: 8,
    paddingLeft: 40,
    boxSizing: "border-box",
  },
  icon: {
    position: "absolute",
    top: 8,
    left: 8,
  },
  buttonHolder: {
    textAlign: "center",
    marginTop: 12,
  },
  subContainer: {
    marginTop: 15,
  },
  subTitle: {
    marginBottom: 8,
  },
});

export default function InputFields({ userData, input, setInput, userUID }) {
  const classes = useStyles();

  const [addPhoneDisplay, setAddPhoneDisplay] = React.useState("button");
  const [inputPhone, setInputPhone] = React.useState(null);
  const [addButtonDisabled, setAddButtonDisabled] = React.useState(false);
  const { phone_number, email, sms_verified } = userData;

  const handleChange = (prop) => (event) => {
    setInput({ ...input, [prop]: event.target.value });
  };

  const handlePhoneInput = () => {
    if (!inputPhone) {
      setAddButtonDisabled(false);
      return;
    }
    if (!input.country) {
      setAddButtonDisabled(false);
      alert("Please select a country");
      return;
    }
    const phoneString = inputPhone.match(/\d+/g).join("");
    if (phoneString.length !== 10) {
      alert("Enter full number please");
      setAddButtonDisabled(false);
      return;
    } else {
      const addPhone = fire
        .functions()
        .httpsCallable("addPhoneAndSendConfirmationText");
      addPhone({ phone_number: phoneString, country: input.country }).then((result) => {
        setAddPhoneDisplay("button");
        setAddButtonDisabled(false);
      });
    }
  };

  const cancelPhoneEntryProcess = () => {
    fire
      .firestore()
      .collection("users")
      .doc(userUID)
      .update({
        phone_number: "",
        sms_permission: false,
      })
      .then(() => {
        setAddPhoneDisplay("button");
      });
  };

  return (
    <div>
      <div className={classes.inputHolder}>
        <input
          type="text"
          id="emailChangeInput"
          className={classes.input}
          placeholder={email}
          onChange={handleChange("email")}
          disabled
        />
        <MailOutlineIcon className={classes.icon} />
      </div>
      <div className={classes.subContainer}>
        <div className={classes.subTitle}>Country</div>
        <Select
          name="country"
          value={input.country || ''}
          onChange={handleChange("country")}
          input={<CustomInput />}
        >
          <MenuItem value="canada">Canada</MenuItem>
          <MenuItem value="usa">USA</MenuItem>
          <MenuItem value="uk">United Kingdom</MenuItem>
        </Select>
      </div>
      {addPhoneDisplay === "numberEntry" ? (
        <AddPhone
          setAddPhoneDisplay={setAddPhoneDisplay}
          handlePhoneInput={handlePhoneInput}
          setInputPhone={setInputPhone}
          phone_number={phone_number}
          addButtonDisabled={addButtonDisabled}
          setAddButtonDisabled={setAddButtonDisabled}
        />
      ) : (
        <div
          className={classes.inputHolder}
          onClick={() => setAddPhoneDisplay("numberEntry")}
        >
          <input
            type="text"
            id="phoneChangeInput"
            className={classes.input}
            style={{ cursor: "pointer" }}
            placeholder={phone_number}
            onChange={handleChange("phone_number")}
          />
          <PhoneIcon className={classes.icon} />
        </div>
      )}
      {phone_number && !sms_verified && (
        <ConfirmPhone
          cancelPhoneEntryProcess={cancelPhoneEntryProcess}
          setAddPhoneDisplay={setAddPhoneDisplay}
          userUID={userUID}
          phone_number={phone_number}
        />
      )}
    </div>
  );
}
