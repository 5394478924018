import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputFields from "./InputFields";
import Switches from "./Switches";
import { Button } from "@material-ui/core";
import fire from "../../fire";
import { withRouter } from "react-router-dom";



const useStyles = makeStyles({
  container: {
    paddingTop: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: 145,
  },
  card: {
    maxWidth: 400,
    width: "calc(95% - 32px)",
    padding: 16,
    margin: 16,
  },
  headerText: {
    fontSize: "1.2em",
    fontWeight: 700,
    letterSpacing: -0.5,
    color: "#13C0DD",
  },
  contactContainer: {
    margin: "20px 0",
    textAlign: "center",
  },
  contactTitle: {
    fontSize: "13pt",
    color: "#13C0DD",
  },
  contactSubText: {
    marginTop: 8,
    fontSize: "11pt",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: 30,
  },
  button: {
    backgroundColor: "#13C0DD",
    border: "none",
    boxShadow: "none",
    height: 50,
    fontSize: 15,
    color: "white",
    width: "100%",
    "&:hover": {
      color: "#13C0DD",
    },
  },
  logoutButton: {
    marginTop: 20,
    backgroundColor: "#C41230",
    border: "none",
    boxShadow: "none",
    height: 50,
    fontSize: 15,
    color: "white",
    width: "100%",
    "&:hover": {
      color: "#C41230",
    },
  },
  subContainer: {
    marginTop: 15,
  },
  subTitle: {
    marginBottom: 8,
  },
});

export default withRouter(function Reminders({ userData, userUID }) {
  const classes = useStyles();

  const [input, setInput] = React.useState({...userData});

  const handleSubmit = (event) => {
    event.preventDefault();
    fire
      .firestore()
      .collection("users")
      .doc(userUID)
      .update(input)
      .then(() => {
        alert("Account Successfully Updated");
      })
      .catch(() => {
        alert("Error during Account Update");
      });
  };

  return (
    <div className={classes.container}>
      <div className={classes.card}>
        <div className={classes.headerText}>Update Your Reminders</div>
        <InputFields
          userData={userData}
          input={input}
          setInput={setInput}
          userUID={userUID}
        />
        <Switches userData={userData} input={input} setInput={setInput} />
        <div className={classes.contactContainer}>
          <div className={classes.contactTitle}>Contact Us</div>
          <div className={classes.contactSubText}>help@santevia.com</div>
          <div className={classes.contactSubText}><a href="https://support.santevia.com">Santevia Support Portal</a></div>
          <div className={classes.contactSubText}>1-866-943-9220</div>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            onClick={handleSubmit}
            className={classes.button}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
});
