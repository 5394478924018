import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    position: "relative",
    borderTop: "8px solid #F4F4F4",
  },
  underlay: {
    position: "absolute",
    top: 0,
    height: "83%",
    width: "100%",
  },
  overlay: {
    boxSizing: "border-box",
    height: "100%",
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    color: "black",
    fontSize: "1em",
    fontWeight: 500,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
});

export default function ProgressBar({
  daysRemaining,
  percentRemaining,
  backgroundColor,
}) {
  const classes = useStyles();

  const getText = () => {
    let text = daysRemaining + " days remaining";
    if (percentRemaining <= 25) text = daysRemaining + " days";
    if (percentRemaining <= 10) text = daysRemaining;
    if (percentRemaining <= 3) text = "";
    return text;
  };

  return (
    <div className={classes.container}>
      <div className={classes.underlay}>
        <div
          className={classes.overlay}
          style={{
            width: `${percentRemaining}%`,
            backgroundColor,
          }}
        >
          <div className={classes.text}>{getText()}</div>
        </div>
      </div>
    </div>
  );
}
