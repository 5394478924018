import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DatePicker from "react-datepicker";
import "./DateInput.css";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const useStyles = makeStyles({
  wrapper: {
    marginTop: 10,
    marginBottom: 8,
  },
  labelText: {
    fontSize: "0.68em",
    fontWeight: 700,
    letterSpacing: -0.5,
  },
  textInput: {
    width: "calc(100% - 10px)",
    marginTop: 3,
    borderRadius: 3,
    border: "solid 1px #8E8E8E",
    fontSize: "1.1em",
    letterSpacing: -0.3,
    padding: 8,
    paddingRight: 0,
  },
});

const CustomInputField = React.forwardRef((props, ref) => {
  const classes = useStyles();
  return (
    <input
      type="text"
      readOnly={true}
      ref={ref}
      placeholder={props.value}
      onClick={props.onClick}
      className={classes.textInput}
    />
  );
});

export default function DateInput({ date, setDate }) {
  const classes = useStyles();
  const ref = React.createRef();
  return (
    <div className={classes.wrapper}>
      <div className={classes.labelText}>
        Update the date you last replaced your filter:
      </div>
      <DatePicker
        selected={new Date(date)}
        customInput={<CustomInputField ref={ref} />}
        onChange={(date) => {
          setDate(moment(date).format("M/D/YYYY"));
        }}
        maxDate={Date.now()}
      />
    </div>
  );
}
