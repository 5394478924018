import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import YouTubeIcon from "@material-ui/icons/YouTube";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      flexGrow: 1,
      width: "100%",
      backgroundColor: "#2A2A2A",
      display: "flex",
      justifyContent: "center",
      minHeight: 250,
    },
  },
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    columnGap: 30,
    marginTop: 50,
    width: 900,
  },
  title: {
    marginBottom: 15,
    color: "#EEEEEE",
    fontSize: 12,
    letterSpacing: 1.07,
  },
  links: {
    display: "grid",
    rowGap: 7,
    color: "#A3A3A3",
    fontSize: 12,
  },
  socials: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 33px)",
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.mainContainer}>
      <div className={classes.container}>
        <div className={classes.box}>
          <div className={classes.title}>PRODUCTS</div>
          <div className={classes.links}>
            <Link
              href="https://santevia.com/collections/at-home-filtration"
              color="inherit"
            >
              At Home Filtration
            </Link>
            <Link
              href="https://santevia.com/collections/replacement-filters"
              color="inherit"
            >
              Replacement Filters
            </Link>
            <Link
              href="https://santevia.com/collections/on-the-go-filtration"
              color="inherit"
            >
              On-The-Go Filtration
            </Link>
            <Link
              href="https://santevia.com/collections/replacement-parts"
              color="inherit"
            >
              Replacement Parts
            </Link>
          </div>
        </div>
        <div className={classes.box}>
          <div className={classes.title}>LEGAL</div>
          <div className={classes.links}>
            <Link
              href="https://santevia.com/policies/privacy-policy"
              color="inherit"
            >
              Privacy Policy
            </Link>
            <Link
              href="https://santevia.com/policies/terms-of-service"
              color="inherit"
            >
              Terms of Service
            </Link>
            <Link
              href="https://santevia.com/policies/shipping-policy"
              color="inherit"
            >
              Shipping Policy
            </Link>
            <Link
              href="https://santevia.com/policies/refund-policy"
              color="inherit"
            >
              Refund Policy
            </Link>
            <Link
              href="https://santevia.com/policies/subscription-policy"
              color="inherit"
            >
              Subscription Policy
            </Link>
          </div>
        </div>
        <div className={classes.box}>
          <div className={classes.title}>CONTACT US</div>
          <div className={classes.links}>
            <Link href="https://support.santevia.com/" color="inherit">
              Support Center
            </Link>
            <div>1-866-943-9220</div>
            <div>Help@Santevia.com</div>
          </div>
        </div>
        <div className={classes.box}>
          <div className={classes.title}>COMPANY</div>
          <div className={classes.links}>
            <Link href="https://santevia.com/blogs/vitality" color="inherit">
              Read our Blog
            </Link>
            <Link
              href="https://santevia.com/pages/store-locator"
              color="inherit"
            >
              Find a Store
            </Link>
            <Link
              href="https://santevia.com/pages/industry-program"
              color="inherit"
            >
              Industry Program
            </Link>
            <Link href="https://santevia.com/pages/retailers" color="inherit">
              Become a Retailer
            </Link>
          </div>
        </div>
        <div className={classes.box}>
          <div className={classes.title}>SOCIAL</div>
          <div className={classes.links}>
            <div className={classes.socials}>
              <Link href="https://instagram.com/santevia" color="inherit">
                <InstagramIcon />
              </Link>
              <Link href="https://www.facebook.com/santevia" color="inherit">
                <FacebookIcon />
              </Link>
              <Link
                href="https://www.youtube.com/channel/UC9Cp8p0U9oiMY2lNDVe5Bjw"
                color="inherit"
              >
                <YouTubeIcon />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
