import React, { useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import "./DateSelect.css";

export default function DateSelect({ AddProduct, setProduct }) {
  const [open, setOpen] = useState(true);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        margin="normal"
        id="date-picker-dialog"
        onChange={() => {}}
        open={open}
        disableFuture
        onClose={() => {
          setOpen(false);
          setProduct(null);
        }}
        onAccept={(date) => AddProduct(date)}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        TextFieldComponent={() => null}
      />
    </MuiPickersUtilsProvider>
  );
}
