import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import ProductInfo from "./ProductInfo";
import ModalForm from "./ModalForm";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import "./ProductModal.css";

export default function ProductModal({
  productData,
  daysRemaining,
  percentRemaining,
  productID,
  userUID,
  refresh,
}) {
  const [open, setOpen] = React.useState(false);
  const ref = React.useRef();

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    if (ref.current) {
      setOpen(false);
    }
  }

  React.useEffect(() => {
    return () => {
      ref.current = false;
    };
  }, []);

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <MoreVertIcon style={{ color: "black" }} />
      </IconButton>
      <Dialog ref={ref} open={open} onClose={handleClose}>
        <DialogContent
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <ProductInfo
            productData={productData}
            daysRemaining={daysRemaining}
            percentRemaining={percentRemaining}
          />
          <ModalForm
            productData={productData}
            productID={productID}
            handleClose={handleClose}
            userUID={userUID}
            refresh={refresh}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
