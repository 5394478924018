import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Store from "./Store";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#13C0DD",
    },
    secondary: {
      main: "#183D37",
    },
  },
});

ReactDOM.render(
  <Store>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Store>,
  document.getElementById("root")
);

serviceWorker.register();
