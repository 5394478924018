import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Visibility } from "@material-ui/icons";
import "./Confirm.css";
import fire from "../../fire";
import { withRouter } from "react-router-dom";
import Header from "./Header";

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      marginTop: 75,
      maxWidth: "100%",
      maxHeight: 600,
    },
  },
  container: {
    width: "100%",
    padding: "15px 15px 0 15px",
    backgroundColor: "#2A2A2A",
    backgroundImage: "url(creek-background.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      justifyContent: "center",
    },
  },
  titleText: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    fontSize: 50,
    color: "#fff",
    fontWeight: "bold",
    padding: "50px 45px 38px",
    [theme.breakpoints.up("md")]: {
      alignSelf: "flex-start",
      marginTop: 100,
      marginRight: 20,
    },
  },
  card: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    padding: "20px 29px",
    backgroundColor: "#183D37",
    width: "80%",
    maxWidth: 400,
    [theme.breakpoints.up("md")]: {
      borderRadius: 10,
      width: 250,
    },
  },
  inputHolder: {
    marginTop: 16,
    position: "relative",
  },
  textInput: {
    width: "calc(100% - 32px)",
    borderRadius: 3,
    fontSize: 15,
    padding: 15,
    background: "#fff",
    border: "none",
    color: "#8E8E8E",
  },
  icon: {
    position: "absolute",
    top: 12,
    right: 14,
    color: "#13C0DD",
    "&:hover": {
      cursor: "pointer",
    },
  },
  buttonContainer: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    paddingTop: 18,
    width: "100%",
  },
  button: {
    backgroundColor: "#13C0DD",
    border: "none",
    boxShadow: "none",
    height: 50,
    fontSize: 15,
    color: "white",
    width: "100%",
    "&:hover": {
      color: "#13C0DD",
    },
  },
  forgotContainer: {
    marginTop: 25,
    marginBottom: 20,
    display: "flex",
    justifyContent: "center",
  },
  forgotButton: {
    fontSize: 12,
    color: "#E5CAA2",
    cursor: "pointer",
  },
  orContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  line: {
    height: 2,
    width: "40%",
    backgroundColor: "#E5CAA2",
    borderRadius: 5,
  },
  or: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#fff",
  },
  socialMediaContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginTop: 24,
  },
  socialMediaText: {
    letterSpacing: 1.1,
    fontSize: 17,
    color: "#fff",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 18,
    width: 90,
  },
  socialIcon: {
    height: 18,
    width: 18,
    cursor: "pointer",
  },
  signUpContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 40,
    marginBottom: 80,
    [theme.breakpoints.up("md")]: {
      marginBottom: 10,
    },
  },
  signUp: {
    fontSize: 12,
    color: "#fff",
    marginRight: 5,
  },
  signUpButton: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#E5CAA2",
    textDecoration: "underline",
    cursor: "pointer",
  },
}));

export default withRouter(function Login({ history }) {
  const classes = useStyles();
  const [showPassword, setShowPassword] = React.useState(false);

  const [inputs, setInput] = React.useState({
    email: "",
    password: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInput({ ...inputs, [name]: value });
  };

  function loginUser(event) {
    event.preventDefault();
    const { email, password } = inputs;
    if (!email || !password) {
      alert("Missing Inputs!");
    } else {
      fire
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(() => {
          history.push("/");
        })
        .catch(function (error) {
          var errorCode = error.code;
          var errorMessage = error.message;
          if (errorCode === "auth/wrong-password") {
            alert("Wrong password.");
          } else {
            alert(errorMessage);
          }
          console.log(error);
        });
    }
  }

  function handleUserPasswordReset() {
    const { email } = inputs;

    if (!email) {
      alert("Enter your email above and press this button again!");
      return;
    }

    fire
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        alert("Password reset sent. Please check your email.");
      })
      .catch((err) => {
        alert(
          "Something went wrong. Please double check the email you entered."
        );
      });
  }

  return (
    <>
      <Header />
      <div className={classes.contentContainer}>
        <div className={classes.container}>
          <div className={classes.titleText}>Welcome Back!</div>
          <div className={classes.card}>
            <form onSubmit={loginUser}>
              <div className={classes.inputHolder}>
                <input
                  type="text"
                  className={classes.textInput}
                  placeholder="Email Address"
                  onChange={handleChange}
                  name="email"
                  autoComplete="email"
                />
              </div>
              <div className={classes.inputHolder}>
                <input
                  type={showPassword ? "text" : "password"}
                  className={classes.textInput}
                  placeholder="Password"
                  onChange={handleChange}
                  name="password"
                  autoComplete="current-password"
                />
                <Visibility
                  className={classes.icon}
                  onClick={() => setShowPassword(!showPassword)}
                />
              </div>
              <div className={classes.buttonContainer}>
                <Button
                  type="submit"
                  id="loginButton"
                  variant="contained"
                  className={classes.button}
                >
                  Login
                </Button>
              </div>
            </form>
            <div className={classes.forgotContainer}>
              <div
                className={classes.forgotButton}
                onClick={handleUserPasswordReset}
              >
                Forgot Password?
              </div>
            </div>
            {/* <div className={classes.orContainer}>
              <div className={classes.line}></div>
              <div className={classes.or}>or</div>
              <div className={classes.line}></div>
            </div>
            <div className={classes.socialMediaContainer}>
              <div className={classes.socialMediaText}>Social Media Login</div>
              <div className={classes.iconContainer}>
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/logos/google-white.svg"
                  }
                  alt="google login"
                  className={classes.socialIcon}
                />
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/logos/facebook-white.svg"
                  }
                  alt="facebook login"
                  className={classes.socialIcon}
                />
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/logos/twitter-white.svg"
                  }
                  alt="twitter login"
                  className={classes.socialIcon}
                />
              </div>
            </div> */}
            <div className={classes.signUpContainer}>
              <div className={classes.signUp}>Don't have an account?</div>
              <div
                className={classes.signUpButton}
                onClick={() => history.push("/register")}
              >
                Sign up!
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
