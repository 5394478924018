import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import Header from "./Header";

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      marginTop: 75,
      maxWidth: "100%",
      maxHeight: 600,
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row-reverse",
      marginTop: 50,
      width: 900,
    },
  },
  productImg: {
    [theme.breakpoints.up("md")]: {
      width: 420,
    },
  },
  welcomeContainer: {
    width: "80%",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "column",
      height: 225,
    },
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      justifyContent: "space-evenly",
      height: 150,
    },
  },
  welcome: {
    width: "100%",
    textAlign: "center",
    fontSize: 36,
    color: "#13C0DD",
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
    },
  },
  subText: {
    margin: "15px 10px",
    fontSize: 18,
    width: "100%",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
    },
  },
  buttonContainer: {
    flexGrow: 1,
    display: "flex",
    marginTop: 10,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  buttonSignUp: {
    backgroundColor: "#2A2A2A",
    border: "none",
    boxShadow: "none",
    height: 40,
    fontSize: 16,
    marginBottom: 8,
    marginRight: 10,
    color: "#fff",
    width: "75%",
    "&:hover": {
      backgroundColor: "#2A2A2A",
      color: "#fff",
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      height: 50,
    },
  },
  button: {
    backgroundColor: "#fff",
    border: "2px solid #13C0DD",
    boxShadow: "none",
    height: 40,
    marginBottom: 30,
    fontSize: 16,
    color: "#13C0DD",
    width: "75%",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#13C0DD",
    },
    [theme.breakpoints.down("sm")]: {
      height: 50,
    },
  },
}));

export default withRouter(function Welcome({ history }) {
  const classes = useStyles();

  return (
    <>
      <Header welcome />
      <div className={classes.contentContainer}>
        <div className={classes.content}>
          <img
            src={process.env.PUBLIC_URL + "product-group-new.jpg"}
            alt="Santevia products"
            className={classes.productImg}
            width={322}
          />
          <div className={classes.welcomeContainer}>
            <div className={classes.textContainer}>
              <div className={classes.welcome}>Welcome to Filter Ease!</div>
              <div className={classes.subText}>
                Receive notifications when it's time to change your filter.
              </div>
            </div>
            <div className={classes.buttonContainer}>
              <Button
                id="loginDirectButton"
                className={classes.buttonSignUp}
                onClick={() => history.push("/register")}
              >
                Sign up
              </Button>
              <Button
                id="loginDirectButton"
                className={classes.button}
                onClick={() => history.push("/login")}
              >
                Login
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
