export const products = {
  classicPitcher: {
    lifeSpan: 60,
    sku: '9AP-421',
    name: 'Classic Pitcher',
    shortName: 'Classic Pitcher Filter'
  },
  minaPitcher: {
    lifeSpan: 60,
    sku: '9MS-471',
    name: 'MINA Pitcher',
    shortName: 'MINA Filter'
  },
  powerStick: {
    lifeSpan: 90,
    sku: '9ST-208',
    name: 'Water Bottle Filter',
    shortName: 'Water Bottle Filter'
  },
  countertop: {
    fluorideFilter: {
      lifeSpan: 120,
      sku: '9GS-102',
      name: 'Gravity Water System - Fluoride Filter',
      shortName: 'Fluoride Filter'
    },
    fiveStageFilter: {
      lifeSpan: 180,
      sku: '9GS-121',
      name: 'Gravity Water System - 5-Stage Filter',
      shortName: '5-Stage Filter'
    },
    ceramicPreFilter: {
      lifeSpan: 365,
      sku: '9GS-132',
      name: 'Gravity Water System - Ceramic Pre-filter',
      shortName: 'Ceramic Pre-filter'
    },
    mineralStones: {
      lifeSpan: 724,
      sku: '9GS-141',
      name: 'Gravity Water System - Mineral Stones',
      shortName: 'Mineral Stones'
    }
  },
  bathFilter: {
    lifeSpan: 60,
    sku: '9BA-608',
    name: 'Bath Filter',
    shortName: 'Bath Filter'
  },
  showerFilter: {
    lifeSpan: 120,
    sku: '9SF-252',
    name: 'Shower Filter',
    shortName: 'Shower Filter'
  },
  glassWaterSystem: {
    minaFilter: {
      lifeSpan: 60,
      sku: '9MS-471',
      name: 'Glass Water System - MINA Filter',
      shortName: 'MINA Filter'
    },
    mineralStones: {
      lifeSpan: 724,
      sku: '9GS-141',
      name: 'Glass Water System - Mineral Stones',
      shortName: 'Mineral Stones'
    }
  }
}

// {   
//   "9AP-421": { "name": "Alkaline Pitcher - Classic", "life" : 60},
//   "9MS-471": { "name": "Alkaline Pitcher - MINA Slim", "life" : 60},
//   "9ST-208": { "name": "Power Stick", "life" : 90 },
//   "9ST-408": { "name": "Recovery Stick", "life" : 90 },
//   "9GS-102": { "name": "Gravity Water System - Fluoride", "life" : 120 },
//   "9GS-121": { "name": "Gravity Water System - 5-Stage", "life" : 180 },
//   "9GS-132": { "name": "Gravity Water System - Ceramic", "life" : 365 },
//   "9GS-141": { "name": "Gravity Water System - Mineral", "life" : 720 },
//   "9BA-608": { "name": "Bath Filter", "life" : 60 },
//   "9SF-252": { "name": "Shower Filter", "life" : 120 }
// }