import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardInfo from "./CardInfo";
import ProgressBar from "./ProgressBar";
import "./ProductCard.css";
import moment from "moment";

const useStyles = makeStyles({
  card: {
    width: "95%",
    maxWidth: 500,
    marginTop: 10,
    border: "8px solid #F4F4F4",
    boxShadow: "none",
  },
  mainGrid: {
    display: "grid",
    height: "100%",
    gridTemplateColumns: "30% auto",
    gridTemplateRows: "108px 45px",
  },
  progressBar: {
    gridColumn: "1 / span 2",
  },
  imageHolder: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    borderRight: "8px solid #F4F4F4",
  },
  infoHolder: {
    paddingLeft: 5,
  },
});

export const imageMap = {
  "9AP-421": "ClassicPitcher.jpg",
  "9MS-471": "mina-filter.jpg",
  "9ST-208": "water-bottle-filter.jpg",
  "9GS-102": "fluoride-filter.jpg",
  "9GS-121": "5-stage-filter.jpg",
  "9GS-132": "Ceramic.jpg",
  "9GS-141": "MineralStones.jpg",
  "9BA-608": "bath-filter-new.jpg",
  "9SF-252": "ShowerFilter.png",
};

export default function ProductCard({ product, userUID, refresh = () => {} }) {
  const classes = useStyles();

  const productData = product.data();
  const productID = product.id;

  const { next_replace_date } = productData;
  const daysRemaining = Math.max(
    moment(next_replace_date, "M/D/YYYY").diff(moment(), "days"),
    0
  );

  const lifeSpan = productData[[`${productData.usage}_life_span`]];
  const percentRemaining = (daysRemaining / lifeSpan) * 100;
  let lifeSpanBackgroundColor = "#13C0DD";
  let lifeSpanColor = "#2A2A2A";
  if (percentRemaining <= 25) {
    lifeSpanBackgroundColor = lifeSpanColor = "#E5CAA2";
  }
  if (percentRemaining <= 10) {
    lifeSpanBackgroundColor = lifeSpanColor = "#C41230";
  }

  return (
    <Card className={classes.card}>
      <div className={classes.mainGrid}>
        <div className={classes.imageHolder}>
          <CardMedia
            component="img"
            height="100"
            image={imageMap[productData.product]}
          />
        </div>
        <div className={classes.infoHolder}>
          <CardInfo
            productData={productData}
            daysRemaining={daysRemaining}
            percentRemaining={percentRemaining}
            color={lifeSpanColor}
            productID={productID}
            userUID={userUID}
            refresh={refresh}
          />
        </div>
        <div className={classes.progressBar}>
          <ProgressBar
            daysRemaining={daysRemaining}
            percentRemaining={percentRemaining}
            backgroundColor={lifeSpanBackgroundColor}
          />
        </div>
      </div>
    </Card>
  );
}
