import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import { withRouter } from "react-router-dom";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIcon from "@material-ui/icons/Phone";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { Select, MenuItem } from "@material-ui/core";
import CopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import InputBase from "@material-ui/core/InputBase";
import fire from "../fire";
import ProductCard from "./Home/ProductCard";
import CircularProgress from "@material-ui/core/CircularProgress";

const SELECT_COUNTRY = "SELECT_COUNTRY";
const SELECT_PURCHASE_LOCATION = "SELECT_PURCHASE_LOCATION";

const CustomInput = withStyles((theme) => ({
  root: {
    width: "100%",
  },
  input: {
    position: "relative",
    backgroundColor: "#F4F4F4",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
  },
}))(InputBase);

const useStyles = makeStyles({
  container: {
    paddingTop: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: 145,
  },
  card: {
    maxWidth: 400,
    width: "calc(95% - 32px)",
    padding: 16,
    margin: 16,
  },
  headerText: {
    fontSize: "1.25em",
    fontWeight: 700,
    letterSpacing: -0.5,
    color: "#13C0DD",
  },
  subText: {
    marginTop: 7,
    color: "#707070",
  },
  inputHolder: {
    marginTop: 12,
    position: "relative",
  },
  input: {
    width: "100%",
    borderRadius: 3,
    border: "solid 1px #707070",
    fontSize: "1.1em",
    letterSpacing: -0.3,
    padding: 8,
    paddingLeft: 40,
    boxSizing: "border-box",
  },
  editInput: {
    width: "75%",
    borderRadius: 3,
    border: "solid 1px #707070",
    fontSize: "1.1em",
    letterSpacing: -0.3,
    padding: 8,
    boxSizing: "border-box",
  },
  icon: {
    position: "absolute",
    top: 8,
    left: 8,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: 12,
  },
  button: {
    backgroundColor: "#13C0DD",
    border: "none",
    boxShadow: "none",
    height: 50,
    fontSize: 15,
    color: "white",
    width: "100%",
    "&:hover": {
      color: "#13C0DD",
    },
  },
  saveButton: {
    backgroundColor: "#13C0DD",
    border: "none",
    boxShadow: "none",
    height: 39,
    fontSize: 15,
    marginLeft: 7,
    color: "white",
    width: "24%",
    "&:hover": {
      color: "#13C0DD",
    },
  },
  editContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 10,
  },
  redText: {
    marginTop: 10,
    color: "#C41230",
  },
  foundContainer: {
    marginTop: 25,
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "95%",
    marginTop: 10,
    padding: 10,
    backgroundColor: "#F4F4F4",
  },
  item: {
    color: "#707070",
  },
  iconContainer: {
    display: "flex",
  },
  iconAction: {
    fontSize: 12,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    color: "#13C0DD",
    marginLeft: 15,
    "&:hover": {
      cursor: "pointer",
    },
  },
});

export default withRouter(function Admin() {
  const classes = useStyles();
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [foundUser, setFoundUser] = React.useState(null);
  const [error, setError] = React.useState("");
  const [search, setSearch] = React.useState(false);

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  }

  const returnUserDataToAuth = fire
    .functions()
    .httpsCallable("returnUserDataToAuth");

  const handleSubmit = () => {
    setSearch(true);
    if (!phone && !email) {
      setError("Add a phone number or email to search");
      setSearch(false);
      setFoundUser(null);
    } else {
      returnUserDataToAuth({ phone, email })
        .then((result) => {
          if (result.data === "No Match Found" || result.data === "User is not an Admin" ) setError(result.data);
          else {
            setError("");
            setFoundUser(result.data);
            setSearch(false);
          }
        })
        .catch((error) => {
          console.log({ error });
          setFoundUser(null);
          setError("Error");
          setSearch(false);
        });
    }
  };

  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then()
      .catch((error) => console.log({ error }));
  };

  const Row = ({ text, id, type, noEdit }) => {
    const [edit, setEdit] = React.useState(false);
    const [value, setValue] = React.useState(text);

    const handleInput = (ev) => {
      ev.preventDefault();
      setValue(ev.target.value);
    };

    const handleSave = (ev) => {
      ev.preventDefault();
      fire
        .firestore()
        .collection("users")
        .doc(foundUser.uid)
        .update({ [id]: value })
        .then(() => {
          setFoundUser((prevState) => {
            const newState = { ...prevState };
            newState[id] = value;
            return newState;
          });
        })
        .catch(() => {
          alert("Error during Account Update");
        });
    };

    return !edit ? (
      <div className={classes.row}>
        <div className={classes.item}>{text}</div>
        <div className={classes.iconContainer}>
          {!noEdit && (
            <div className={classes.iconAction} onClick={() => setEdit(true)}>
              <EditOutlinedIcon />
              Edit
            </div>
          )}
          <div className={classes.iconAction} onClick={() => handleCopy(text)}>
            <CopyOutlinedIcon />
            Copy
          </div>
        </div>
      </div>
    ) : (
      <div className={classes.editContainer}>
        {[SELECT_COUNTRY, SELECT_PURCHASE_LOCATION].includes(type) ? (
          <>
            {SELECT_COUNTRY === type ? (
              <Select
                name={id}
                value={value}
                onChange={handleInput}
                input={<CustomInput />}
              >
                <MenuItem value="n/a">N/A</MenuItem>
                <MenuItem value="canada">Canada</MenuItem>
                <MenuItem value="usa">USA</MenuItem>
                <MenuItem value="uk">United Kingdom</MenuItem>
              </Select>
            ) : (
              <Select
                name={id}
                value={value}
                onChange={handleInput}
                input={<CustomInput />}
              >
                <MenuItem value="n/a">N/A</MenuItem>
                <MenuItem value="in-store">In-store</MenuItem>
                <MenuItem value="santevia.com">Santevia.com</MenuItem>
                <MenuItem value="amazon">Amazon</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            )}
            <Button
              variant="contained"
              onClick={handleSave}
              className={classes.saveButton}
            >
              Save
            </Button>
          </>
        ) : (
          <>
            <input
              type="text"
              id={id}
              className={classes.editInput}
              placeholder={id[0].toUpperCase() + id.slice(1)}
              value={value}
              onChange={handleInput}
            />
            <Button
              variant="contained"
              onClick={handleSave}
              className={classes.saveButton}
            >
              Save
            </Button>
          </>
        )}
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.card}>
        <div className={classes.headerText}>Admin</div>
        <div className={classes.subText}>Search a user's email or phone</div>
        <div>
          <div className={classes.inputHolder}>
            <input
              type="text"
              id="email"
              className={classes.input}
              placeholder="Email Address"
              value={email}
              onChange={(ev) => {
                setEmail(ev.target.value);
                setPhone("");
              }}
            />
            <MailOutlineIcon className={classes.icon} />
          </div>
          <div className={classes.inputHolder}>
            <input
              type="text"
              id="phone"
              className={classes.input}
              placeholder="Phone"
              value={phone}
              onChange={(ev) => {
                setPhone(ev.target.value);
                setEmail("");
              }}
            />
            <PhoneIcon className={classes.icon} />
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            onClick={handleSubmit}
            className={classes.button}
            disabled={search}
          >
            {search ? (
              <CircularProgress />
            ) : (
              <>
                <SearchIcon style={{ marginRight: 10 }} />
                Search
              </>
            )}
          </Button>
        </div>
        <div className={classes.redText}>
          {error ? error : "This will return a user's information"}
        </div>
        {foundUser && (
          <div className={classes.foundContainer}>
            <div className={classes.headerText}>
              {foundUser.first} {foundUser.last}
            </div>
            <div className={classes.subText}>Details below</div>
            <Row text={foundUser.first || "First Name"} id="first" />
            <Row text={foundUser.last || "Last Name"} id="last" />
            <Row
              text={formatPhoneNumber(foundUser.phone_number) || "Phone"}
              id="phone"
              noEdit
            />
            <Row text={foundUser.email || "Email"} id="email" noEdit />
            <Row
              text={foundUser.country || "Country"}
              id="country"
              type={SELECT_COUNTRY}
            />
            <Row
              text={foundUser.purchase_location || "Purchase Location"}
              id="purchase_location"
              type={SELECT_PURCHASE_LOCATION}
            />
            {Object.keys(foundUser.product).map((key) => {
              const prod = {
                id: key,
                data: () => {
                  return {
                    ...foundUser.product[key],
                  };
                },
              };
              return (
                <ProductCard
                  product={prod}
                  userUID={foundUser.uid}
                  key={key}
                  refresh={handleSubmit}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
});
