import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import NameInput from "./FormFields/NameInput";
import DateInput from "./FormFields/DateInput";
import UsageToggle from "./FormFields/UsageToggle";
import SubmitButtons from "./FormFields/SubmitButtons";
import { UserContext } from "../../../Store";
import fire from "../../../fire";
import moment from "moment";

const useStyles = makeStyles({
  outerBox: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: 15,
    marginLeft: 18,
  },
  innerBox: {
    width: "90%",
  },
  title: {
    fontSize: "1.5em",
    marginTop: 15,
    marginBottom: 7,
  },
  subTitle: {
    fontSize: ".8em",
    marginBottom: 10,
  },
});

export default function ModalForm({
  productData,
  productID,
  handleClose,
  userUID,
  refresh,
}) {
  const classes = useStyles();

  const [user] = React.useContext(UserContext);

  const { last_replace_date, usage: prodUsage, user_custom_name } = productData;
  const [date, setDate] = React.useState(last_replace_date);
  const [usage, setUsage] = React.useState(prodUsage);
  const [name, setName] = React.useState("");

  function updateProduct() {
    const newName = name || user_custom_name;
    const newLifeSpan = productData[`${usage}_life_span`];
    const nextReplaceDate = moment(date, "M/D/YYYY")
      .add(newLifeSpan, "days")
      .format("M/D/YYYY");

    fire
      .firestore()
      .collection("users")
      .doc(userUID || user.uid)
      .collection("product")
      .doc(productID)
      .update({
        user_custom_name: newName,
        last_replace_date: date,
        next_replace_date: nextReplaceDate,
        usage,
      })
      .then(() => {
        refresh();
      });

    handleClose();
  }

  return (
    <div className={classes.outerBox}>
      <div className={classes.innerBox}>
        <div className={classes.title}>{productData.user_custom_name}</div>
        <div className={classes.subTitle}>{productData.product_name}</div>
        <DateInput date={date} setDate={setDate} />
        <NameInput
          name={name}
          setName={setName}
          placeholderName={user_custom_name}
        />
        <UsageToggle usage={usage} setUsage={setUsage} />
        <SubmitButtons
          updateProduct={updateProduct}
          outerHandleClose={handleClose}
          user={user}
          productID={productID}
          userUID={userUID}
          refresh={refresh}
        />
      </div>
    </div>
  );
}
